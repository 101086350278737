import axios from "axios"

const prefix = "flujo-dinero/cuentas";

const flujoDinero = {
    listaTransferenciasCuentasBancarias: params => axios.get(`${prefix}/lista`, { params }),
    selectCuentasBancarias: () => axios.get(`${prefix}/select`),
    tablaCuentasBancarias: () => axios.get(`${prefix}/tabla`),
    editarTransferencia: params => axios.put(`${prefix}/transferencia/${params.id}`, params ),
    crearCuentaBancaria: params => axios.post(`${prefix}/cuenta`, params ),
    editarCuentaBancaria: params => axios.put(`${prefix}/cuenta/${params.id}`, params ),
    eliminarCuentaBancaria: id => axios.delete(`${prefix}/cuenta/${id}`),
    cambioEstadoCuentaBancaria: id => axios.put(`${prefix}/cambio-estado/${id}`),
};

export default flujoDinero
